var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.layout,
    { tag: "component" },
    [_c("router-view", { staticClass: "HELLO child-view" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }