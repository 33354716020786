var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "transition-opacity",
      class: {
        "pointer-events-none opacity-50": _vm.loading
      }
    },
    [
      _c("MultipleChoice", {
        attrs: {
          "show-tick": _vm.question.meta.showTick,
          multiple: _vm.multiple,
          locked: _vm.locked || _vm.hasAnswered,
          "force-selected": _vm.userAnswers,
          "show-answers": _vm.showAnswers,
          question: _vm.question
        },
        on: { selected: _vm.chooseOptions }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-right mt-8" },
        [
          _c(
            "SaveButton",
            {
              attrs: {
                loading: _vm.loading,
                complete: _vm.showAnswers,
                "next-url": _vm.nextUrl
              },
              on: { submit: _vm.submit }
            },
            [
              _vm.status
                ? [_vm._v("\n          " + _vm._s(_vm.status) + "\n      ")]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }