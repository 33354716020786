var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "base-slide-title",
        { staticClass: "mt-8 mb-3", attrs: { tag: "h2" } },
        [
          _c("transition", { attrs: { name: "fade-simple", mode: "out-in" } }, [
            _vm.showAnswers
              ? _c("div", { key: "if-show-answers" }, [
                  _vm._v("\n          Great!\n          "),
                  _c("span", { staticClass: "text-theme-highlight" }, [
                    _vm._v(_vm._s(_vm.matchingPercent))
                  ]),
                  _vm._v(" of your community agree with you!\n        ")
                ])
              : _c("div", { key: "if-not-show-answers" }, [
                  _vm._v(_vm._s(_vm.question.text))
                ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "-m-1 md:-m-2 flex flex-wrap" },
        _vm._l(_vm.options, function(ref, i) {
          var bgColor = ref.bgColor
          var image = ref.image
          var id = ref.id
          var text = ref.text
          return _c(
            "div",
            {
              key: "choice-" + i + "-" + id,
              staticClass: "relative p-1 md:p-2 w-full",
              class: {
                "md:w-c6": [1, 2, 4].includes(_vm.options.length),
                "md:w-c4": [3, 5, 6, 7, 8, 9, 10].includes(_vm.options.length)
              }
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "relative rounded-lg m-0 overflow-hidden border-2 ar-16x9",
                  class: {
                    "opacity-100 border-theme-highlight":
                      _vm.selected.indexOf(id) > -1,
                    "border-black-20": _vm.selected.indexOf(id) === -1,
                    "opacity-60":
                      _vm.selected.length && _vm.selected.indexOf(id) === -1
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "inset-0 absolute z-0",
                      style: {
                        "background-color": bgColor
                      }
                    },
                    [
                      image
                        ? _c("img", {
                            staticClass:
                              "w-full h-full object-cover object-center",
                            attrs: { src: image.url, alt: image.title }
                          })
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "inset-0 absolute z-1",
                    class: {
                      "text-gradient-light": !_vm.showAnswers,
                      "text-gradient-dark": _vm.showAnswers
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "right-0 absolute top-0 bottom-0 z-1 w-6 transition-opacity",
                      class: {
                        "opacity-0": !_vm.showAnswers
                      }
                    },
                    [
                      _c("div", {
                        staticClass:
                          "top-25 left-0 right-0 ml-4 bg-white h-px z-2 absolute"
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "top-50 left-0 right-0 ml-2 bg-white h-px z-2 absolute"
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "top-75 left-0 right-0 ml-4 bg-white h-px z-2 absolute"
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass:
                          "bar right-0 left-0 bottom-0 bg-theme-highlight absolute z-2",
                        style: {
                          height: _vm.calcPercent(id)
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    _vm.selectComponent,
                    {
                      tag: "component",
                      staticClass: "toggle-button z-3",
                      attrs: {
                        "aria-label": "Toggle " + text,
                        "aria-pressed": _vm.selected.indexOf(id) > -1
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggle(id)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "inset-0 p-4 absolute z-1 flex flex-col justify-end items-start"
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "transition-opacity transition-delay text-white self-end percent-bar mb-auto mt-0 text-30 font-light",
                              class: {
                                "opacity-0": !_vm.showAnswers
                              }
                            },
                            [_vm._v(_vm._s(_vm.calcPercent(id)))]
                          ),
                          _vm._v(" "),
                          _vm.showTick
                            ? _c(
                                "div",
                                { staticClass: "relative mb-2" },
                                [
                                  _c("svg-icon", {
                                    staticClass: "text-white relative z-1",
                                    attrs: { icon: "circle-tick", size: "6" }
                                  }),
                                  _vm._v(" "),
                                  _vm.selected.indexOf(id) > -1
                                    ? _c("svg-icon", {
                                        staticClass:
                                          "text-theme-highlight absolute z-0 left-0 top-0",
                                        attrs: { icon: "circle", size: "6" }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.showTick && _vm.selected.indexOf(id) > -1
                            ? _c(
                                "div",
                                { staticClass: "relative mb-2" },
                                [
                                  _c("svg-icon", {
                                    staticClass: "text-white relative z-1",
                                    attrs: { icon: "circle-tick", size: "6" }
                                  }),
                                  _vm._v(" "),
                                  _c("svg-icon", {
                                    staticClass:
                                      "text-theme-highlight absolute z-0 left-0 top-0",
                                    attrs: { icon: "circle", size: "6" }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "label text-left text-16 md:text-18 leading-tight pr-6"
                            },
                            [_vm._v(_vm._s(text))]
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }