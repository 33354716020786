<template>
  <div key="finish-step" :class="{
      'opacity-0': !visible
    }" class="flex items-stretch transition-opacity min-h-xl">
    <div class="flex flex-col justify-center order-1 p-6 md:order-0 md:p-8 lg:p-12 md:w-c6">

      <div class="mt-0 mb-auto font-bold text-14 text-theme-highlight">
        One more thing...
      </div>

      <template v-if="!complete">
        <div class="mt-4 font-light max-w-108 text-16 leading-medium">
          <p class="mb-8 font-light leading-tight text-28">{{ customMessage }}</p>
          <form @submit.prevent="submitDemographics" class="w-full" action="/">
            <div class="flex items-center">
              <label class="mr-4 font-normal whitespace-no-wrap min-w-30 text-13">
                Full Name:<span class="text-red-90">*</span>
              </label>
              <div class="relative flex-1 ml-auto max-w-50 md:max-w-64">
                <input type="text" class="flex-grow w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow border-grey-20 text-16" required :maxlength="maxLength" v-model="fullName" />
              </div>
            </div>
            <div class="flex items-center mt-2">
              <label class="mr-4 font-normal max-w-30 text-13">
                House/Apartment Number:<span class="text-red-90">*</span>
              </label>
              <div class="relative flex-1 ml-auto max-w-50 md:max-w-64">
                <input type="text" class="flex-grow w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow border-grey-20 text-16" required :maxlength="maxLength" v-model="houseNumber" />
              </div>
            </div>
            <div class="flex items-center mt-2">
              <label class="mr-4 font-normal max-w-30 text-13">
                House/Apartment Name (if applicable):
              </label>
              <div class="relative flex-1 ml-auto max-w-50 md:max-w-64">
                <input type="text" class="flex-grow w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow border-grey-20 text-16" :maxlength="maxLength" v-model="houseName" />
              </div>
            </div>
            <div class="flex items-center mt-2">
              <label class="mr-4 font-normal whitespace-no-wrap min-w-30 text-13">
                Street:<span class="text-red-90">*</span>
              </label>
              <div class="relative flex-1 ml-auto max-w-50 md:max-w-64">
                <input type="text" class="flex-grow w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow border-grey-20 text-16" required :maxlength="maxLength" v-model="street" />
              </div>
            </div>
            <div class="flex items-center mt-2">
              <label class="mr-4 font-normal whitespace-no-wrap min-w-30 text-13">
                Postcode:<span class="text-red-90">*</span>
              </label>
              <div class="relative flex-1 ml-auto max-w-50 md:max-w-64">
                <input placeholder="e.g. N1" type="text" class="flex-grow w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow border-grey-20 text-16" required :maxlength="maxPostcode" v-model="postcode" />
              </div>
            </div>
            <div class="flex items-center mt-2">
              <label class="mr-4 font-normal whitespace-no-wrap min-w-30 text-13">
                Email:
              </label>
              <div class="relative flex-1 ml-auto max-w-50 md:max-w-64">
                <input placeholder="me@example.com" type="email" class="flex-grow w-full h-10 pl-4 pr-2 font-sans font-light bg-white border rounded shadow border-grey-20 text-16" :maxlength="maxLength" v-model="email" />
              </div>
            </div>
            <div class="flex justify-end w-full mt-2">
              <button type="submit" class="h-10 px-6 mt-4 font-serif antialiased italic font-normal text-white rounded-full cursor-pointer bg-theme-highlight text-14">Submit</button>
            </div>
            <div class="mt-4 font-light text-12 leading-medium rte" v-html="smallPrint"></div>
          </form>
        </div>
      </template>

      <template v-else>
        <div class="w-full my-auto max-w-94">
          <svg-icon icon="party" class="mt-auto mb-2 party-hat" />
          <h2 class="mb-2 font-medium text-28">You're all done!</h2>
          <div class="mb-2 font-light leading-tight text-28">Thanks for completing the poll.</div>
          <router-link id="card-close-button" class="inline-flex items-center h-10 px-6 mt-4 ml-auto font-serif antialiased italic font-normal bg-white border rounded-full cursor-pointer text-14 text-theme-highlight border-theme-highlight hover:bg-theme-highlight hover:text-white" to="/">Close</router-link>
        </div>
      </template>

      <div class="h-px mt-auto mb-0"></div>

    </div>
    <div class="relative self-stretch md:order-1 order-0 md:w-c6">
      <img :src="image" class="relative z-0 hidden object-cover object-center w-full h-full md:block" />
    </div>

  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  layout: 'card-overlay',
  data() {
    return {
      locked: false,
      fullName: '',
      houseNumber: '',
      houseName: '',
      street: '',
      postcode: '',
      email: '',
      complete: false,
      visible: false,
      maxLength: 255,
      maxPostcode: 10,
    }
  },
  watch: {
    email(a) {
      if(a) {
        this.status = ''
      }
    }
  },
  computed: {
    ...mapState( 'polls', [
      'image',
      'slug',
      'customMessage',
      'smallPrint',
    ]),
  },
  mounted() {
    var APP = window.APP || {};

    this.visible = false

    document.body.classList.add('fix-body')
    setTimeout( () => {
      this.visible = true
    }, 400 )
  },
  beforeRouteLeave( to, from, next ) {
    // We're closing...
    if ( to.path === "/" ) {
      document.body.classList.remove('fix-body')
      setTimeout( () => {
        // console.log("Next?")
        next();
      }, 200 );
    } else {
      next();
    }
  },
  created() {
    if ( !this.slug ) {
      this.$store.dispatch('polls/load', this.$route.params.poll);
    }
  },
  methods: {
    checkCanSubmit() {
      if ( !this.fullName || !this.houseNumber || !this.street || !this.postcode ) {
        return false
      }
      return true
    },
    async submitDemographics() {

      if( !this.checkCanSubmit() ) return

      const success = await this.$store.dispatch('question/demographics', {
        fullName: this.fullName,
        houseNumber: this.houseNumber,
        houseName: this.houseName,
        street: this.street,
        postcode: this.postcode,
        email: this.email,
        age: '',
        gender: '',
      })

      if ( success ) {
        this.complete = true;
      } else {
        this.locked = false
        this.complete = false;
      }

    },
  },
}
</script>

<style scoped>
.party-hat {
  transform: rotate(-30deg);
  width: 58px;
  height: 57px;
  @apply text-theme-highlight;
  @apply fill-current;
}
</style>
