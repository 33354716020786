<template>
  <div>
    <base-slide-title tag="h2" class="mt-8 mb-3">
      <transition name="fade-simple" mode="out-in">
        <div key="if-show-answers" v-if="showAnswers">
          Great!
          <span class="text-theme-highlight">{{ matchingPercent }}</span> of your community agree with you!
        </div>
        <div key="if-not-show-answers" v-else>{{ question.text }}</div>
      </transition>
    </base-slide-title>
    <div class="-m-1 md:-m-2 flex flex-wrap">
      <div
        :class="{
          'md:w-c6': [1,2,4].includes(options.length),
          'md:w-c4': [3,5,6,7,8,9,10].includes(options.length),
        }"
        class="relative p-1 md:p-2 w-full"
        v-for="({ bgColor, image, id, text }, i) in options"
        :key="`choice-${i}-${id}`"
      >
        <div
          :class="{
          'opacity-100 border-theme-highlight': selected.indexOf( id ) > -1,
          'border-black-20': selected.indexOf( id ) === -1,
          'opacity-60': selected.length && selected.indexOf( id ) === -1
		    }"
          class="relative rounded-lg m-0 overflow-hidden border-2 ar-16x9"
        >
          <div class="inset-0 absolute z-0" :style="{
              'background-color' : bgColor
            }">
            <img v-if="image"
              :src="image.url"
              class="w-full h-full object-cover object-center"
              :alt="image.title"
            />
          </div>

          <div
            :class="{
                'text-gradient-light' : !showAnswers,
                'text-gradient-dark': showAnswers
            }"
            class="inset-0 absolute z-1"
          ></div>

          <!-- Grid lines -->
          
          <div :class="{
            'opacity-0' : !showAnswers
          }" class="right-0 absolute top-0 bottom-0 z-1 w-6 transition-opacity">
            <div class="top-25 left-0 right-0 ml-4 bg-white h-px z-2 absolute"></div>
            <div class="top-50 left-0 right-0 ml-2 bg-white h-px z-2 absolute"></div>
            <div class="top-75 left-0 right-0 ml-4 bg-white h-px z-2 absolute"></div>
            <div
              class="bar right-0 left-0 bottom-0 bg-theme-highlight absolute z-2"
              :style="{
                height: calcPercent( id )
              }"
            ></div>
          </div>
          

          <component
            :is="selectComponent"
            @click="toggle(id)"
            :aria-label="`Toggle ${text}`"
            :aria-pressed="selected.indexOf( id ) > -1"
            class="toggle-button z-3"
          >
            <div class="inset-0 p-4 absolute z-1 flex flex-col justify-end items-start">
              <div
                class="transition-opacity transition-delay text-white self-end percent-bar mb-auto mt-0 text-30 font-light"
                :class="{
                  'opacity-0' : !showAnswers
                }"
              >{{ calcPercent( id ) }}</div>

              <div v-if="showTick" class="relative mb-2">
                <svg-icon icon="circle-tick" class="text-white relative z-1" size="6" />
                <svg-icon
                  v-if="selected.indexOf( id ) > -1"
                  icon="circle"
                  class="text-theme-highlight absolute z-0 left-0 top-0"
                  size="6"
                />
              </div>
              <div v-if="!showTick && selected.indexOf( id ) > -1" class="relative mb-2">
                <svg-icon icon="circle-tick" class="text-white relative z-1" size="6" />
                <svg-icon
                  icon="circle"
                  class="text-theme-highlight absolute z-0 left-0 top-0"
                  size="6"
                />
              </div>
              <div class="label text-left text-16 md:text-18 leading-tight pr-6">{{ text }}</div>
            </div>
          </component>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

function toggleArrayItem(a, v) {
  var i = a.indexOf(v);
  if (i === -1)
    a.push(v);
  else
    a.splice(i, 1);
}

export default {
  props: {
    forceSelected: {
      type: [Boolean, Array],
      required: false,
      default: false,
    },
    showTick: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showAnswers: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selectComponent() {
      return (this.locked || this.forceSelected.length) ? 'div' : 'button'
    },
    options() {
      return this.question.options;
    },
    selected() {
      if (this.forceSelected.length) {
        this.forceSelected
      }
      return this.manualSelected
    },

    total() {
      if (this.question.answers && this.question.answers.total) {
        return this.question.answers.total
      }
      return false;
    },

    matchingPercent() {
      let matchedValue = 0
      Object.entries(this.total).forEach(([key, value]) => {

        if (this.forceSelected.indexOf(key) > -1) {
          matchedValue = value
        }
      })

      return `${Math.round(matchedValue / this.totalCount * 100)}%`
    },

    totalCount() {
      let count = 0;
      if (this.total) {
        Object.entries(this.total).forEach(([key, value]) => {
          if (value) {
            count += parseInt(value)
          }
        })
      }
      return count
      //   console.log(this.total)
      //   for (const key in this.total) {
      //     if (total.hasOwnProperty(key)) {
      //       const value = this.total[key];
      //       console.log(value)
      //       if (value) {
      //         count += parseInt(value);
      //         console.log(count)
      //       }
      //     }
      //     console.log('----')
      //   }

      //   console.log("No Total")
      // }
      // return count;
    }
  },
  data() {
    return {
      manualSelected: [],
      // showAnswers: false,
    }
  },
  methods: {
    calcPercent(id) {

      const count = this.totalCount
      const value = this.total[id]
      let percent = 0

      if (value && this.showAnswers) {
        percent = parseInt(value) / count * 100
      }

      return `${Math.round(percent)}%`
    },
    singleToggle(id) {
      if (this.manualSelected.indexOf(id) === -1) {
        this.manualSelected = [id]
      } else {
        this.manualSelected = []
      }
    },
    multipleToggle(id) {
      toggleArrayItem(this.manualSelected, id)
    },
    toggle(id) {

      if (this.locked || this.forceSelected.length > 0) { return }

      if (this.multiple) {
        this.multipleToggle(id)
      } else {
        this.singleToggle(id)
      }

      this.$emit('selected', this.manualSelected)
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle-button {
  @apply block inset-0 absolute p-0 w-full h-full;

  &:focus {
    outline: 0;
    box-shadow: inset 0 0 0px 2px rgba(skyblue, 1);
  }
}

.bar {
  transition: height .3s ease-in;
}

.text-gradient-dark {
  transition: background 200ms ease;
  background: linear-gradient(180deg, rgba(0,0,0,0.3) 10%, rgba(0,0,0,0.9) 100%);
}

.text-gradient-light {
  background: linear-gradient(180deg, rgba(0,0,0,0) 50%, rgba(0,0,0,0.85) 100%);
}

.label {
  @apply font-bold text-white antialiased;
  @screen md {
    @apply text-18;
  }
}
</style>