import { post, get } from "@/vue/store/_ajax"

export default {
  namespaced: true,
  state: {
    question: false,
    loading: false
  },
  getters: {
    hasAnswered(state) {
      const { answers } = state.question;
      const { hasAnswered } = answers;
      return hasAnswered;
    }
  },
  mutations: {
    loading(state, isLoading) {
      state.loading = isLoading;
    },
    setActiveQuestion(state, activeQuestion) {
      state.question = activeQuestion;
    },
    loadAnswers(state, answers) {
      state.answers = answers;
    }
  },
  actions: {
    async demographics(
      { commit, state, rootGetters },
      { fullName, houseNumber, houseName, street, postcode, email, age, gender }
    ) {
      let error = false;

      commit("loading", true);

      return await post("polls/demographics", {
        fullName,
        houseNumber, 
        houseName, 
        street, 
        postcode, 
        email, 
        age, 
        gender
      })
        .catch(e => {
          let error = e;

          if (e.response && e.response.data) {
            error = e.response.data.error;
          }

          commit("errors/alert", error, { root: true });
        })
        .finally(() => commit("loading", false));
    },
    async finish({ commit, state, rootGetters }, { email }) {
      let error = false;

      commit("loading", true);

      return await post("polls/finish", {
        email
      })
        .catch(e => {
          let error = e;

          if (e.response && e.response.data) {
            error = e.response.data.error;
          }

          commit("errors/alert", error, { root: true });
        })
        .finally(() => commit("loading", false));
    },
    async submit({ commit, state, rootGetters }, { qid, id, answer }) {
      let error = false;

      commit("loading", true);

      const r = await post("polls/submit-answer", {
        qid,
        answer
      })
        .catch(e => {
          let error = e;

          if (e.response && e.response.data) {
            error = e.response.data.error;
          }

          commit("errors/alert", error, { root: true });
        })
        .finally(() => commit("loading", false));

      if (!r) return false;

      const { data: response } = r;
      const { data, success, message } = response;
      if (success) {
        commit("loadAnswers", data.answers);

        commit(
          "polls/loadAnswersForQuestion",
          {
            qid: data.qid,
            answers: data.answers
          },
          { root: true }
        );

        return true;
      } else {
        commit("errors/alert", message ? message : error, { root: true });
        return false;
      }
    }
  }
};
