<template>
  <div :class="{
      'pointer-events-none opacity-50': loading,
    }" class="transition-opacity">

    <MultipleChoice
      :show-tick="question.meta.showTick"
      :multiple="multiple"
      :locked="locked || hasAnswered"
      :force-selected="userAnswers"
      :show-answers="showAnswers"
      @selected="chooseOptions"
      :question="question"
    />

    <div class="text-right mt-8">
      <SaveButton @submit="submit" :loading="loading" :complete="showAnswers" :next-url="nextUrl">
        <template v-if="status">
            {{ status }}
        </template>
      </SaveButton>
    </div>
  </div>
</template>

<script>
import MultipleChoice from '@/vue/components/inputs/MultipleChoice.vue';
import SaveButton from '@/vue/components/inputs/SaveButton.vue';
import { mapGetters } from 'vuex'


export default {
  props: {
    question: {
      type: Object,
    },
    nextUrl: {
      type: [String, Boolean],
      required: false,
    }
  },
  computed: {
    ...mapGetters('question', [
      'hasAnswered',
    ]),
    loading() {
      return this.$store.state.question.loading
    },
    answers() {    
      return this.question ? this.question.answers : false
    },
    // Either show answers if its complete, or hasanswered shows
    // that the controller response said it the question is already answered
    showAnswers() {
      return this.hasAnswered || this.complete
    },
    userAnswers() {
      if ( this.showAnswers && this.answers ) {
        const { user } = this.answers
        if ( user ) {
          // convert into a simple array of keys...
          const arr = []
          Object.entries( user ).forEach(([key,value]) => {
            if ( value ) arr.push(key)
          })
          return arr;
        }
        return this.chosenOptions 
      }

      return false
    },
    multiple() {
      return this.question.type === 'multipleChoice'
    },
  },
  components: {
    MultipleChoice,
    SaveButton,
  },
  data() {
    return {
      locked: false,
      chosenOptions: [],
      complete: false,
      status: '',
    }
  },
  methods: {
    chooseOptions(chosenOptions) {
      this.status = ''
      this.chosenOptions = chosenOptions;
    },
    checkCanSubmit() {
      if ( this.chosenOptions.length <= 0 ) {
        this.status = this.multiple ? "You must choose at least one option" : "You must select an option"

        return false
      }

      return true
    },
    async submit() {

      if ( !this.checkCanSubmit() ) return

      this.locked = true

      const success = await this.$store.dispatch('question/submit', {
        answer: this.chosenOptions,
        qid: this.question.qid,
        id: this.question.pollId,
      })

      if ( success ) {
        this.complete = true
      } else {
        this.locked = false
      }

    }
  }
}
</script>

<style lang="scss" scoped>
</style>