<!-- 

This is the single entry for all our vue routes, that are contained in /pages/

This should remain very simple and not do anything too opinionated. The router-view
component gets auto registered by Vue-router and is basically the route's component,
so if you requested a route like /my-page which directed to pages/my-page.vue then
<router-view> will be the my-page component.

The wrapping component <component :is="layout">

Is the component defined by the layout property in a page.

So if a page had the property layout: 'overlay' then it will try to add the 'layout/overlay.vue' component, but if it doesn't exist, you'll have problems, but there won't be many
layouts and most may use default. It's an optional convention, and we could easily
strip it away without much impact, but it helps for things like dealing with a consistent
overlay style and putting everything inside the one 'card'.

-->

<template>
  <component :is="layout">
    <router-view class="HELLO child-view"></router-view>
  </component>
</template>

<script>
export default {
  data() {
    return {
      transitionName: 'slide-left',
      layout: 'layout-invisible',
    }
  },
  mounted() {

  },
  watch: {
    '$route': {
      immediate: true,
      handler(to, from) {
        let layout = to.meta.layout
        if (!layout) {
          layout = 'default'
        }

        this.layout = `layout-${layout}`;
      }
    }
  },
}
</script>


<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease 0.2s;
  border: 20px solid #f00 !important;
}

.fade-leave-active {
  // position: absolute;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>